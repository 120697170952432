






































































import useAttachment from "@/use/attachment";
import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  emits: ["close", "update"],
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    const { uploadAttachments, fileTypesString } = useAttachment({ root });

    const axiosInstance = computed(
      () => root.$store.getters["api/getInstance"] as AxiosInstance
    );

    const form = ref();
    const isFormValid = ref(true);

    const error = ref<string | null>(null);
    const loading = ref(false);

    const model = reactive<{
      description: string | null;
      attachments: File[];
    }>({
      description: null,
      attachments: [],
    });

    const resetModel = () => {
      model.description = null;
      model.attachments = [];
    };

    const rules: {
      description?: ((v: string | null) => boolean | string)[];
      attachments?: ((v: File[]) => boolean | string)[];
    } = {
      description: [(v: string | null) => !!v || "Opis jest wymagany"],
      attachments: [],
    };

    const close = () => {
      emit("close");

      nextTick(() => {
        error.value = null;
        resetModel();

        nextTick(() => {
          form.value?.resetValidation();
        });
      });
    };

    const onSubmit = async () => {
      error.value = null;
      await form.value?.validate();

      if (isFormValid.value) {
        loading.value = true;

        const attachmentIds = await uploadAttachments(model.attachments)
          .then(({ data }) =>
            data.attachments.map((attachment: { id: string }) => attachment.id)
          )
          .catch(() => []);

        const data = {
          status: "in_progress",
          description: model.description || undefined,
          attachmentIds: attachmentIds || undefined,
          vehicleId: props.vehicle?.id || root.$route.params.id,
        };

        await axiosInstance.value
          .post("damage", data)
          .then(() => {
            emit("update");
            close();
          })
          .catch(() => {
            error.value = "Nie udało się zgłosić szkody";
          })
          .finally(() => (loading.value = false));
      }
    };

    return {
      fileTypesString,
      form,
      isFormValid,
      error,
      loading,
      model,
      resetModel,
      rules,
      close,
      onSubmit,
    };
  },
});
